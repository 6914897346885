<template>
    <div>
        <div class="divider" style="margin-top: 0;margin-bottom: 5px;">
            <span style="color:#555;"><v-icon style="color:#555!important;">assignment_ind</v-icon>Our Community</span>
            <span class="dashboard_refresh_button">
                <v-icon medium  @click="memberCountCall(true)" v-if ="refresh_status==false">autorenew</v-icon>
                <v-progress-circular indeterminate color="#ccc" v-else :size="20"></v-progress-circular>

            </span>
        </div>
       

        <div class="member-stat-bar">
            <ul>
                <li>
                    <div class="representation">
                        <v-icon>arrow_drop_down_circle</v-icon>
                    </div>
                    <div class="member-label" style="flex: 3.5">
                        <h3>Total Member</h3>
                        <p>Students, Guardian, Teacher and {{otherMembers}} Others</p>
                    </div>
                    <div class="counter">
                        <h2>{{allMemberCount}}</h2>
                    </div>
                </li>

                <li>
                    <div class="representation">
                        <img src="https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/student.png" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Students</h3>
                        <p>Male {{memberCount.student.male}}, Female {{memberCount.student.female}}</p>
                    </div>
                    <div class="stats">
                        <p>{{memberCount.student.total || 0}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.student.total,'student')"></v-progress-linear>
                    </div>
                </li>


                <li>
                    <div class="representation">
                        <img src="https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/guardian.png" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Guardian</h3>
                        <p>{{members.guardian.unassigned.count || 0}} Unassigned</p>
                    </div>
                    <div class="stats">
                        <p>{{memberCount.guardian}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.guardian,'guardian')"></v-progress-linear>
                    </div>
                </li>

                <li>
                    <div class="representation">
                        <img src="https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/teacher.png" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Teachers</h3>
                        <p>Male {{memberCount.teacher.male}}, Female {{memberCount.teacher.female}}</p>
                    </div>

                    <div class="stats">
                        <p>{{memberCount.teacher.total || 0}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.teacher.total)"></v-progress-linear>
                    </div>
                </li>

                <!-- <li >
                    <div class="representation">
                        <img src="https://eacademy.sgp1.digitaloceanspaces.com/eacademy/icons/accountant.png" alt="">
                    </div>
                    <div class="member-label">
                        <h3>Staff</h3>
                        <p>Teachers {{memberCount.teaching_staff_count}}, Others {{memberCount.non_teaching_staff_count}}</p>
                    </div>

                    <div class="stats">
                        <p>{{memberCount.teaching_staff_count + memberCount.non_teaching_staff_count || 0}}</p>
                        <v-progress-linear style="margin-top: 0;"
                                           :value="memberPercentageEvaluation(memberCount.teaching_staff_count + memberCount.non_teaching_staff_count)"></v-progress-linear>
                    </div>
                </li> -->
              

            </ul>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        data() {
            return {
                memberCount: {
                    teacher: {
                        total: '',
                        male: '',
                        female: ''
                    },
                    student: {
                        total: '',
                        male: '',
                        female: ''
                    },
                    guardian: 0,
                    member: 0,
                    teaching_staff_count: 0,
                    non_teaching_staff_count: 0,
                },
                refresh_status:false,
            }
        },
        computed: {
            ...mapState(['members']),

            allMemberCount() {
                return this.memberCount.student.total + this.memberCount.guardian + this.memberCount.member;
            },
            otherMembers(){
                return this.memberCount.member-this.memberCount.teacher.total;
            }
        },
        mounted() {
            this.memberCountCall();
        },
        methods: {
            memberPercentageEvaluation(count) {
                if (!count)
                    return 0;
                return (count * 100) / this.allMemberCount;
            },

            memberCountCall(status=false) {
                if(localStorage.getItem("dashboard") === null || status){
                    this.refresh_status = true;
                    localStorage.removeItem("dashboard");

                    this.$rest.get('api/dashboard/gender-count').then(data => {
                        let studentTotal = 0;
                        let $this = this;
                        this.memberCount.member = data.data.member;
                        this.memberCount.guardian = data.data.guardian.guardian;
                        data.data.student.map(function (item) {
                            studentTotal += item.cnt;
                            if (JSON.parse(item.gender) === 'FEMALE')
                                $this.memberCount.student.female = item.cnt;
                            if (JSON.parse(item.gender) === 'MALE')
                                $this.memberCount.student.male = item.cnt;
                        });
                        this.memberCount.student.total = studentTotal;

                        let teacherTotal = 0;

                        data.data.teacher.map(function (item) {
                            teacherTotal += item.cnt;
                            if (JSON.parse(item.gender) === 'FEMALE')
                                $this.memberCount.teacher.female = item.cnt;
                            if (JSON.parse(item.gender) === 'MALE')
                                $this.memberCount.teacher.male = item.cnt;
                        });
                        this.memberCount.teacher.total = teacherTotal;
                        if(data.status === 200){
                            $this.refresh_status = false
                        }
                        var total_community_members = {
                            member_count : data.data.member,
                            student_count:studentTotal,
                            guardian_count:data.data.guardian.guardian,
                            teacher_count:teacherTotal,
                            male_student_count:$this.memberCount.student.male,
                            female_student_count:$this.memberCount.student.female,

                            female_teacher_count:$this.memberCount.teacher.female,
                            male_teacher_count:$this.memberCount.teacher.male,

                            non_teaching_staff_count: data.data.nonTeachingStaffCount,
                            teaching_staff_count: data.data.teachingStaffCount,
                        }
                        // let old_value =  JSON.parse(window.localStorage.getItem('dashboard'))
                        let member_count = {
                            total_community_members,
                            // ...old_value

                        }
                        window.localStorage.setItem('dashboard', JSON.stringify(member_count));
                    });
                }else{

                    let get_community_member_count = JSON.parse(window.localStorage.getItem('dashboard'));
                    this.memberCount.member = get_community_member_count.total_community_members.member_count;
                    this.memberCount.teacher.total = get_community_member_count.total_community_members.teacher_count;
                    this.memberCount.student.total = get_community_member_count.total_community_members.student_count;
                    this.memberCount.guardian = get_community_member_count.total_community_members.guardian_count;
                    this.memberCount.student.female = get_community_member_count.total_community_members.female_student_count;
                    this.memberCount.student.male = get_community_member_count.total_community_members.male_student_count;
                    this.memberCount.teacher.female =get_community_member_count.total_community_members.female_teacher_count
                    this.memberCount.teacher.male =get_community_member_count.total_community_members.male_teacher_count
                    this.memberCount.teaching_staff_count = get_community_member_count.total_community_members.teaching_staff_count
                    this.memberCount.non_teaching_staff_count = get_community_member_count.total_community_members.non_teaching_staff_count

                }
            },


        }
    }
</script>